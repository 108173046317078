import { useContext } from "react";
import { FormContext } from "../../context/FormContext";
import classes from "./ImageUpload.module.css";
import UploadTile from "./components/UploadTile";

export default function ImageUpload() {
  const { image1State, image2State, image3State, image4State } = useContext(FormContext);

  return (
    <>
      <div className={classes.textContainer}>
        <h1>First, add some photos of your watch</h1>
        <h3>
          Images can be uploaded as .jpeg or .png - for guidance on how to take good photos of your watch{" "}
          <a
            href="https://firebasestorage.googleapis.com/v0/b/sell-your-watch-today.appspot.com/o/documents%2FSubmissionGuidance.pdf?alt=media&token=696a8bb7-b400-433d-8fec-483f2d7f8dba"
            target="_blank"
            rel="noreferrer"
          >
            click here
          </a>
        </h3>
      </div>
      <div className={classes.imagesContainer}>
        <div className={classes.twoImageGroup}>
          <UploadTile imageState={image1State} />
          <UploadTile imageState={image2State} />
        </div>
        <div className={classes.twoImageGroup}>
          <UploadTile imageState={image3State} />
          <UploadTile imageState={image4State} />
        </div>
      </div>
    </>
  );
}
