import { Link } from "react-router-dom";

import classes from "./ContactDetails.module.css";
import { FormContext } from "../../context/FormContext";
import { useContext } from "react";
import TextInput from "../../../../components/TextInput/TextInput";
import YesNoInput from "../../../../components/YesNoInput/YesNoInput";

export default function ContactDetails() {
  const { firstNameState, lastNameState, emailState, termsState, phoneState } = useContext(FormContext);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Finally, tell us how to send you offers</h1>
      <h3 className={classes.secondaryTitle}>
        Your information will only be used to send you offers, we never send spam or give your data to third parties.
        For more information check out our{" "}
        <Link to="/PrivacyPolicy" target="_blank" rel="noreferrer">
          Privacy Policy
        </Link>
      </h3>
      <div className={classes.inputsContainer}>
        <div className={classes.inputRow}>
          <TextInput state={firstNameState} title="First name" subtext="(Required)" />
          <div className={classes.inputDivider} />
          <TextInput state={lastNameState} title="Last name" subtext="(Required)" />
        </div>
        <div className={classes.contactDetailsRow}>
          <TextInput state={emailState} title="Email address" type="email" subtext="(Required)" />
          <div className={classes.inputDivider} />
          <TextInput state={phoneState} title="Contact Number" subtext="(Optional)" />
        </div>
        <div className={`${classes.inputRow} ${classes.termsRow}`}>
          <YesNoInput
            state={termsState}
            question={
              <span>
                Have you read and agreed to our{" "}
                <Link to="/TermsAndConditions" target="_blank" rel="noreferrer">
                  terms and conditions
                </Link>
                ?
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
}
