import classes from "./NavBar.module.css";
import logo from "../../assets/logo.webp";
import { Link } from "react-router-dom";

export default function NavBar() {
  return (
    <div className={classes.container}>
      <div className={classes.navContent}>
        <ul className={classes.navItemList}>
          <li className={classes.navItem}>
            <Link className={classes.navLink} to="/">
              Home
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link className={classes.navLink} to="/GetOffers">
              Get Offers
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link className={classes.navLink} to="/HowItWorks">
              How It Works
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link className={classes.navLink} to="/OurDealers">
              Our Dealers
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link className={classes.navLink} to="/ContactUs">
              Contact Us
            </Link>
          </li>
        </ul>
        <Link to="/">
          <img src={logo} alt="Sell Your Watch Today" className={classes.logo} />
        </Link>
      </div>
    </div>
  );
}
