import { Link } from "react-router-dom";
import classes from "./OurDealers.module.css";
import watchImage from "./assets/Rolex-Datejust.webp";
import BackToHomepage from "../../components/BackToHomepage/BackToHomepage";

function OurDealers() {
  return (
    <>
      <BackToHomepage />
      <img src={watchImage} className={classes.watchImage} alt="Rolex watch" />
      <div className={classes.container}>
        <div className={classes.textBlock}>
          <h1>Our Dealers</h1>
          <p>
            We understand that when selling your watch, you need to be able to trust the person you are selling it to.
            That's why at Sell Your Watch Today we only sell to dealers who have been thoroughly vetted by our team.
          </p>

          <h2>How do I know I can trust the dealers?</h2>
          <p>
            All of the watch dealers we work with are established, professional watch dealers with a proven track record
            of purchasing watches online, so you don't have to worry about dishonest or fraudulent buyers.
          </p>
          <p>
            As an additional layer of security, when you accept an offer you are actually selling your watch to us
            (before we then sell it to the dealer ourselves for the same price). This means you are completely shielded
            from making any contracts or transactions with dealers.
          </p>

          <h2>I am a dealer, can I join the platform?</h2>
          <p>
            If you are a watch dealer and are interested in joining the platform we would love to hear from you. For
            security reasons we are very strict about the dealers we work with, but if you are an established dealer
            with a proven record of buying and selling watches please get in touch using our{" "}
            <Link to="/ContactUs">contact form</Link>.
          </p>
        </div>
      </div>
    </>
  );
}

export default OurDealers;
