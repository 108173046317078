import { getFirestore, doc, setDoc } from "firebase/firestore";
import { uploadBytes, getDownloadURL, ref, getStorage } from "firebase/storage";

export async function uploadFile(file, filepath) {
  const storage = getStorage();
  const storageRef = ref(storage, filepath);
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return url;
}

export async function createSubmissionDoc(submissionID, formState) {
  const firestore = getFirestore();
  const submissionDoc = doc(
    firestore,
    "submissions",
    `${submissionID}_${formState.brandState[0]}_${formState.modelState[0]}`
  );
  const submissionData = {
    submissionID,
    brand: formState.brandState[0],
    model: formState.modelState[0],
    year: formState.yearState[0],
    box: formState.boxState[0],
    papers: formState.papersState[0],
    working: formState.workingState[0],
    email: formState.emailState[0],
    phone: formState.phoneState[0],
    firstName: formState.firstNameState[0],
    lastName: formState.lastNameState[0],
    imageURLs: formState.imageURLs,
    otherInfo: formState.otherInfoState[0],
  };
  await setDoc(submissionDoc, submissionData);
}

export async function sendSubmissionEmails(submissionID, formState) {
  const firestore = getFirestore();
  const imageURLs = formState.imageURLs;
  const numImages = imageURLs.length;
  const d = new Date();
  const todaysDate = String(d.getDate()).padStart(2, "0") + "/" + String(d.getMonth() + 1).padStart(2, "0");
  const submissionData = {
    id: `${submissionID}`,
    brand: formState.brandState[0],
    model: formState.modelState[0],
    year: formState.yearState[0],
    box: formState.boxState[0] ? "Yes" : "No",
    working: formState.workingState[0] ? "Yes" : "No",
    papers: formState.papersState[0] ? "Yes" : "No",
    image1: numImages > 0 ? imageURLs[0] : "",
    image2: numImages > 1 ? imageURLs[1] : "",
    image3: numImages > 2 ? imageURLs[2] : "",
    image4: numImages > 3 ? imageURLs[3] : "",
    otherInfo: formState.otherInfoState[0],
    email: formState.emailState[0],
    phone: formState.phoneState[0],
    firstName: formState.firstNameState[0],
    lastName: formState.lastNameState[0],
  };

  const internalEmail = {
    to: "info@sellyourwatchtoday.co.uk",
    from: "info@sellyourwatchtoday.co.uk",
    template: {
      name: "new-submission-internal",
      data: submissionData,
    },
  };

  const confirmationEmail = {
    to: formState.emailState[0],
    from: "info@sellyourwatchtoday.co.uk",
    template: {
      name: "submission-received",
      data: {
        id: `${submissionID}`,
        firstName: `${formState.firstNameState[0]}`,
      },
    },
  };

  const trelloEmail = {
    to: "rosswtriggol1+4axxtrwdwcwgrqdczo83@boards.trello.com",
    from: "info@sellyourwatchtoday.co.uk",
    message: {
      subject: `${formState.firstNameState[0]} ${formState.lastNameState[0]} - ${formState.brandState[0]} ${formState.modelState[0]} (${todaysDate})`,
      text: `\nSUBMISSION DATA:\nID: ${submissionID}\nBrand: ${submissionData.brand}\nModel: ${submissionData.model}\nYear: ${submissionData.year}\nBox? ${submissionData.box}\nWorking? ${submissionData.working}\nPapers? ${submissionData.papers}\nOther Info: ${submissionData.otherInfo}\nImages:\n${submissionData.image1}\n\n${submissionData.image2}\n\n${submissionData.image3}\n\n${submissionData.image4}\n\n\nCONTACT DETAILS:\nName: ${formState.firstNameState[0]} ${formState.lastNameState[0]}\nEmail: ${formState.emailState[0]}\nPhone: ${formState.phoneState[0]}\n`,
    },
  };

  const submissionDoc = doc(firestore, "mail", `${submissionID}_Submission_${formState.emailState[0]}`);
  const confirmationDoc = doc(firestore, "mail", `${submissionID}_Confirmation_${formState.emailState[0]}`);
  const trelloDoc = doc(firestore, "mail", `Trello_${submissionID}_${formState.emailState[0]}`);
  await setDoc(confirmationDoc, confirmationEmail);
  await setDoc(submissionDoc, internalEmail);
  await setDoc(trelloDoc, trelloEmail);
}

export async function sendContactFormEmails(formState) {
  const firestore = getFirestore();
  const enquiryID = new Date().getTime();
  const d = new Date();
  const todaysDate = String(d.getDate()).padStart(2, "0") + "/" + String(d.getMonth() + 1).padStart(2, "0");

  const internalEmail = {
    to: "info@sellyourwatchtoday.co.uk",
    from: "info@sellyourwatchtoday.co.uk",
    message: {
      subject: `NEW ENQUIRY: ${formState.subjectState[0]}`,
      html: `<p><strong>Name:</strong> ${formState.nameState[0]}</p>
      <p><strong>Email:</strong> ${formState.emailState[0]}</p>
      <p><strong>Phone:</strong> ${formState.phoneState[0]}</p>
      <p><strong>Message:</strong> ${formState.messageState[0]}</p>`,
    },
  };

  const confirmationEmail = {
    to: formState.emailState[0],
    from: "info@sellyourwatchtoday.co.uk",
    template: {
      name: "thanks-for-getting-in-touch",
      data: {
        id: `${enquiryID}`,
        name: `${formState.nameState[0]}`,
      },
    },
  };

  const trelloEmail = {
    to: "rosswtriggol1+4axxtrwdwcwgrqdczo83@boards.trello.com",
    from: "info@sellyourwatchtoday.co.uk",
    message: {
      subject: `ENQUIRY: ${formState.nameState[0]} - ${formState.subjectState[0]} (${todaysDate})`,
      text: `\nID: ${enquiryID}\nName: ${formState.nameState[0]}\nEmail: ${formState.emailState[0]}\nPhone: ${formState.phoneState[0]}\nSubject: ${formState.subjectState[0]}\nMessage: ${formState.messageState[0]}\n`,
    },
  };

  const enquiryDoc = doc(firestore, "mail", `${enquiryID}_Enquiry_${formState.emailState[0]}`);
  const confirmationDoc = doc(firestore, "mail", `${enquiryID}_Enquiry-Confirmation_${formState.emailState[0]}`);
  const trelloDoc = doc(firestore, "mail", `Trello_${enquiryID}_${formState.emailState[0]}_Enquiry`);
  await setDoc(confirmationDoc, confirmationEmail);
  await setDoc(enquiryDoc, internalEmail);
  await setDoc(trelloDoc, trelloEmail);
}
