import classes from "./YesNoInput.module.css";

export default function YesNoInput(props) {
  const [yes, setYes] = props.state;

  return (
    <label className={classes.wrapper}>
      {props.question}
      <div className={classes.inputs}>
        <button className={yes ? classes.selected : undefined} onClick={() => setYes(true)}>
          Yes
        </button>
        <button className={!yes ? classes.selected : undefined} onClick={() => setYes(false)}>
          No
        </button>
      </div>
    </label>
  );
}
