import classes from "./ProgressBar.module.css";

export default function ProgressBar(props) {
  return (
    <>
      <div className={classes.bar}>
        <div className={classes.barGold} style={{ width: `${props.progress}%` }} />
      </div>
    </>
  );
}
