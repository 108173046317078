import classes from "./HowItWorks.module.css";
import { Link } from "react-router-dom";
import HowItWorksTile from "./components/HowItWorksTile.jsx";
import BackToHomepage from "../../components/BackToHomepage/BackToHomepage";

function HowItWorks() {
  return (
    <>
      <BackToHomepage />
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <h1>How does it work?</h1>
          <div className={classes.stepsContainer}>
            <HowItWorksTile
              number={1}
              title={"Fill out the form"}
              description={
                <p>
                  Fill out and submit the form under the <Link to="/GetOffers">'Get Offers'</Link> section{" "}
                  <strong>
                    (submitting the form does not commit you to selling your watch - if you're not happy with your
                    offers you can just ignore them!)
                  </strong>
                </p>
              }
            />
            <HowItWorksTile
              number={2}
              title={"Get offers"}
              description={
                <p>
                  We then send your submission <strong>(excluding your contact information)</strong> to verified watch
                  dealers around the UK who will make offers. We will send you all the offers your watch receives as
                  soon as they come in, at which point you can decide if you would like to accept one.
                </p>
              }
            />
            <HowItWorksTile
              number={3}
              title={"Send your watch"}
              description={
                <p>
                  When you accept an offer we will send you a pre-paid & tracked shipping label to send your watch to
                  the dealer. The dealer also pays us up front so we have the money ready to send when the watch
                  arrives.
                </p>
              }
            />
            <HowItWorksTile
              number={4}
              title={"Get paid"}
              description={
                <p>
                  As soon as the dealer has received and checked the watch we will send payment to your nominated bank
                  account. We aim for the exchange process to take no longer than 2 working days from the moment you
                  send your watch.
                </p>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowItWorks;
