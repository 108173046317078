import { useState, useRef } from "react";
import classes from "./GetOffers.module.css";
import { FormContext } from "./context/FormContext";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import ImageUpload from "./components/ImageUpload/ImageUpload";
import ItemDetails from "./components/ItemDetails/ItemDetails";
import ContactDetails from "./components/ContactDetails/ContactDetails";
import Submitted from "./components/Submitted/Submitted";
import BackToHomepage from "../../components/BackToHomepage/BackToHomepage";
import { uploadFile, createSubmissionDoc, sendSubmissionEmails } from "../../firebase/HelperFunctions";
import ReactLoading from "react-loading";
import { isValidEmail } from "../../Tools/HelperFunctions";

export default function GetOffers() {
  const [submissionState, setSubmissionState] = useState("NOT SUBMITTED");
  const [formStep, setFormStep] = useState(1);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const containerRef = useRef();
  const formState = {
    image1State: useState(""),
    image2State: useState(""),
    image3State: useState(""),
    image4State: useState(""),
    brandState: useState(""),
    modelState: useState(""),
    yearState: useState(""),
    workingState: useState(false),
    papersState: useState(false),
    boxState: useState(false),
    otherInfoState: useState(""),
    firstNameState: useState(""),
    lastNameState: useState(""),
    emailState: useState(""),
    phoneState: useState("+44"),
    termsState: [termsAccepted, setTermsAccepted],
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (!isValidEmail(formState.emailState[0])) {
      window.alert("Please enter a valid email address");
      return;
    }
    setSubmissionState("IN PROGRESS");
    const submissionID = new Date().getTime();
    const imageURLs = await uploadImages(submissionID);
    if (imageURLs === "failed") {
      return;
    }
    createSubmissionDoc(submissionID, { ...formState, imageURLs })
      .then(() => {
        setSubmissionState("SUBMITTED");
      })
      .then(resetFormState)
      .then(sendSubmissionEmails(submissionID, { ...formState, imageURLs }));
  }

  async function uploadImages(submissionID) {
    const images = [
      formState.image1State[0],
      formState.image2State[0],
      formState.image3State[0],
      formState.image4State[0],
    ];
    const nonEmptyImages = images.filter((image) => {
      return image !== "";
    });
    if (nonEmptyImages.length > 0) {
      const imageURLs = [];
      for (let i = 0; i < nonEmptyImages.length; i++) {
        const imageURL = await uploadFile(nonEmptyImages[i], `submissions/${submissionID}/image${i + 1}.jpg`);
        imageURLs.push(imageURL);
      }
      return imageURLs;
    } else {
      setSubmissionState("NOT SUBMITTED");
      return "failed";
    }
  }

  function navigateBack() {
    setFormStep(formStep - 1);
    containerRef.current.scroll(0, 0);
  }

  function navigateForward() {
    setFormStep(formStep + 1);
    containerRef.current.scroll(0, 0);
  }

  function resetFormState() {
    for (const stateName in formState) {
      const [value, setValue] = formState[stateName];
      if (typeof value === "boolean") {
        setValue(false);
      } else {
        setValue("");
      }
    }
  }

  function stepOneIncomplete() {
    return (
      formStep === 1 &&
      formState.image1State[0] === "" &&
      formState.image2State[0] === "" &&
      formState.image3State[0] === "" &&
      formState.image4State[0] === ""
    );
  }

  function stepTwoIncomplete() {
    return formStep === 2 && formState.brandState[0] === "";
  }

  function stepThreeIncomplete() {
    return (
      formState.firstNameState[0] === "" ||
      formState.lastNameState[0] === "" ||
      formState.emailState[0] === "" ||
      !termsAccepted
    );
  }

  if (submissionState === "IN PROGRESS") {
    return (
      <div className="centered">
        <ReactLoading type="bars" color="#d3ba8d" width="100px" />
        <ProgressBar progress={75} />
      </div>
    );
  }
  if (submissionState === "SUBMITTED") {
    return (
      <>
        <BackToHomepage />
        <Submitted />
        <ProgressBar progress={100} />
      </>
    );
  }
  return (
    <FormContext.Provider value={formState}>
      <BackToHomepage />
      <div className={classes.formContainer} ref={containerRef}>
        <div className={classes.subContainer}>
          {formStep === 1 && <ImageUpload />}
          {formStep === 2 && <ItemDetails />}
          {formStep === 3 && <ContactDetails />}
          <div className={classes.buttonContainer}>
            <button onClick={navigateBack} disabled={formStep === 1}>
              Back
            </button>
            {formStep < 3 ? (
              <button onClick={navigateForward} disabled={stepOneIncomplete() || stepTwoIncomplete()}>
                Next
              </button>
            ) : (
              <>
                <button onClick={handleSubmit} disabled={stepThreeIncomplete()}>
                  Submit
                </button>
              </>
            )}
          </div>
        </div>
        <ProgressBar progress={25 * formStep} />
      </div>
    </FormContext.Provider>
  );
}
