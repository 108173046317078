import { Link } from "react-router-dom";
import classes from "./TermsAndConditions.module.css";
import BackToHomepage from "../../components/BackToHomepage/BackToHomepage";

function TermsAndConditions() {
  return (
    <>
      <BackToHomepage />
      <div className={classes.container}>
        <h1>Terms and Conditions</h1>

        <h2>1. Definitions</h2>
        <p>In this agreement (the “Agreement”), the following words will have the following meanings:</p>
        <p>
          <b>“Watch”</b> means an item or group of items you choose to submit to us in accordance with this Agreement.
          “Watches” will be interpreted accordingly.
        </p>
        <p>
          <b>“Submission”</b> means the details provided to us describing your watch.
        </p>
        <p>
          <b>“Dealer”</b> means an individual approved by Sell Your Watch Today to see your Submission.
        </p>
        <p>
          <b>“Successful Dealer”</b> means the Dealer to whom we agreed to sell your watch, following your acceptance of
          their Offer.
        </p>
        <p>
          <b>“Offer”</b> means a proposal for a price a Dealer is to pay us in respect of the watch.
        </p>
        <p>
          <b>“Website”</b> means sellyourwatchtoday.co.uk.
        </p>

        <h2>2. Terms</h2>
        <p>
          This website at sellyourwatchtoday.co.uk is operated by, or on behalf of, Unlimited Innovation Ltd (“Sell Your
          Watch Today”, “We” or “Us”) to provide users a free online watch selling service for watch related
          transactions (“Services”).
        </p>
        <p>
          This Agreement is made between Sell Your Watch Today and you, the Customer. You must accept the terms and
          conditions of this Agreement before using the Services. Your continued use of the Services and Website is
          conditional on your acceptance of and compliance with these terms.
        </p>

        <p>
          <b>2.1</b> This agreement governs our relationship with Customers who are consumers acting as private
          individuals and not charity shops, businesses or other similar commercial or non-commercial organisations.
        </p>

        <p>
          <b>2.2</b> By using our Services you confirm that:
        </p>
        <ul>
          <li>
            <b>2.2.1</b> You are legally capable of entering into binding contracts;
          </li>
          <li>
            <b>2.2.2</b> You are the legal owner or have the permission of the legal owner to freely dispose of all
            watches you send to us.
          </li>
          <li>
            <b>2.2.3</b> You are at least 18 years of age or, if you are under 18 years of age, have obtained a parent’s
            or guardian’s consent to send Watches to us;
          </li>
          <li>
            <b>2.2.4</b> You are based in the UK.
          </li>
          <li>
            <b>2.2.5</b> You are accessing the Website from the UK.{" "}
          </li>
        </ul>

        <p>
          <b>2.3</b> If we find upon inspection of any Watch that you have sent that it has been registered or reported
          lost or stolen, or we believe any Watch you have sent to us could be counterfeit we will notify you by email
          and quarantine the Watch whilst we contact the relevant authorities.
        </p>

        <p>
          <b>2.4</b> The terms of our Privacy Policy which can be found at{" "}
          <Link to={`/PrivacyPolicy`}>https://sellyourwatchtoday.co.uk/PrivacyPolicy</Link> are incorporated into this
          Agreement by reference and apply to your use of the Website. You can review our Privacy Policy at any time
          when using our Website. You acknowledge that we will process your Personal Data on the basis set out in our
          Privacy Policy.
        </p>

        <p>
          <b>2.5</b> Please note that when you call us, or when we call you, your call may be recorded for training and
          monitoring purposes. We will record such calls and retain any recordings in line with our Privacy Policy.
        </p>

        <h2>3. Offers</h2>
        <p>
          <b>3.1</b> An Offer for your watch will remain valid for 7 days, after which the Offer can no longer be
          accepted.
        </p>
        <p>
          <b>3.2</b> To accept an Offer you must respond to our email containing the Offer ("Offer Email") within 7 days
          of receiving the Offer.
        </p>
        <p>
          <b>3.3</b> You will be deemed to have accepted an Offer if at any time during the 7 day period you notify us
          that you wish to accept the Offer by responding to our Offer Email.
        </p>
        <p>
          <b>3.4</b> If you do not respond within 7 days of us sending an Offer, your Submission will no longer be
          processed.
        </p>
        <p>
          <b>3.5</b> If you accept an Offer by virtue of clause 3.3 you are creating a binding legal contract (“the
          Contract”) between you and us. Once we have received payment for the Watch from the Successful Dealer, all
          ownership of the Watch will pass to us. In return for the Watch you will receive the agreed sum detailed in
          the accepted Offer following a 1 day inspection period after the Successful Dealer has received the watch.
        </p>
        <p>
          <b>3.6</b> If you are not happy with an Offer for the Watch, then you may decline by email.
        </p>
        <p>
          <b>3.7</b> Payment for your Watch will be made by Sell Your Watch Today as detailed in clause 5.
        </p>
        <p>
          <b>3.8</b> You have the right to cancel as explained in clause 6.
        </p>

        <h2>4. Sending Your Watch</h2>
        <p>
          <b>4.1</b> There are three ways to send your watch:
        </p>
        <p>
          <b>4.1.1</b> We can provide you with a pre-paid postage label to affix to your parcel, however you agree that
          Sell Your Watch Today may place restrictions on the size or weight of items that you send using the pre-paid
          postage label provided.. This can then be dropped off at the Post Office.
        </p>
        <ul>
          <li>
            This will be insured up to a value of £2,500.00. If you believe that the Watch you wish to send is worth
            more than £2,500.00 please contact us to discuss how best to send it before dispatching.
          </li>
          <li>
            The pre-paid postage label will have a tracking number below the barcode which you should take a photo of.
          </li>
          <li>
            It is recommended that you ask for a receipt which will also contain the tracking number. You will only be
            able to make a claim in the event of a lost Parcel if you have the tracking number.
          </li>
        </ul>

        <p>
          <b>4.1.2</b> We provide pre-paid postage labels and in some cases will offer Home Collection. We will not
          reimburse you the costs of using any alternative postage or carrier. However, if you wish not to use our
          pre-paid option then you can use your own courier. We recommend that you choose a courier with the following
          criteria;
        </p>
        <ul>
          <li>Insurance for the value of your watch.</li>
          <li>
            Tracking is provided. This will ensure that we know your watch has been received by the Successful Dealer.
          </li>
        </ul>

        <p>
          <b>4.1.3</b> In some circumstances if deemed necessary by Sell Your Watch Today we will arrange a free home
          collection where we will deliver the Watch in person.
        </p>

        <p>
          <b>4.2</b> Sell Your Watch Today accepts no liability for Watches which are damaged during postage as a result
          of being poorly or insufficiently packaged. Please ensure that you package the Watch you send to the
          Successful Dealer carefully, for guidance on how to package your Watch, please visit Parcelforce’s website:{" "}
          <a href={`https://www.parcelforce.com/help-and-advice/sending/packaging-guidelines`} rel="noreferrer">
            https://www.parcelforce.com/help-and-advice/sending/packaging-guidelines
          </a>
          .
        </p>

        <h2>5. Payments to Customers</h2>
        <p>
          <b>5.1</b> Payments to Customers who enter into the Contract will be made by bank transfer.
        </p>
        <p>
          <b>5.2</b> Bank transfers will be made using the details supplied by you via telephone or Email.
        </p>
        <p>
          <b>5.3</b> Once the Successful Dealer has received the Watch, payment will be made by Sell Your Watch Today to
          your nominated account.
        </p>
        <p>
          <b>5.4</b> Bank transfers may take up to two days to clear after authorisation, and you should be aware that
          many banks do not process transactions on weekends and bank holidays. If a bank transfer takes longer than
          this to reach your account, please contact Sell Your Watch Today.
        </p>
        <p>
          <b>5.5</b> Sell Your Watch Today will have no liability for any inaccuracy in the payment details that you
          provide to us. Accordingly, Sell Your Watch Today shall have no liability for any loss that you may suffer as
          a result of any payment being sent or transmitted to an incorrect address or account, or delayed in any way,
          as a result of any error made by you.
        </p>

        <h2>6. Cancellation</h2>
        <p>
          <b>6.1</b> You have a legal right to cancel any contract that you make with us at any time, without giving a
          reason, within 14 days beginning on the day after the contract with us is formed. This is called the
          “Cancellation Period”. However, please note that the Cancellation Period will end immediately at the point
          that we begin providing Services to you.
        </p>
        <p>
          <b>6.2</b> As such your right to cancel the Contract will end immediately at the point at which you confirm
          acceptance of an Offer and send your Watch to the Successful Dealer.
        </p>
        <p>
          <b>6.3</b> To cancel any contract you make with Sell Your Watch Today you must notify us by email.
        </p>
        <p>
          <b>6.4</b> Despite conditions 6.1 and 6.2 above, if you tell us you wish to cancel the Contract after we have
          begun providing the Services, we will make efforts to ensure your Contract is cancelled. We cannot guarantee
          this, however, and you must be aware that once you confirm acceptance of an Offer, conditions 6.1 and 6.2 will
          apply.
        </p>
        <p>
          <b>6.5</b> Sell Your Watch Today also retains the right to cancel the Contract if your Watch does not match
          the description supplied by you.
        </p>
        <p>
          <b>6.6</b> Sell Your Watch Today will only be able to cancel the Contract within 1 day of the Successful
          Dealer receiving the Watch.
        </p>

        <h2>7. Restrictions</h2>

        <p>
          <b>7.1</b> You must not send us any Item which it would be illegal for you to post or sell, for our chosen
          carrier to handle, or which in our reasonable opinion it would be unlawful for us to store or purchase from
          you (including, without limitation, counterfeit or stolen Items, firearms and other offensive weapons, or
          hazardous chemicals). A list of restricted Items can be found on the Parcelforce website at:{" "}
          <a
            href={`https://www.parcelforce.com/help-and-advice/sending/prohibitions-and-restrictions`}
            target="_blank"
            rel="noreferrer"
          >
            https://www.parcelforce.com/help-and-advice/sending/prohibitions-and-restrictions
          </a>
          .
        </p>
        <p>
          <b>7.2</b> By breaching clause 7.1, you may be committing an offence. Sell Your Watch Today has a number of
          legal obligations to comply with in respect of the carriage of Items, including but not limited to an
          obligation not to carry or accept for carriage Items that are deemed to be "Dangerous Goods" as defined in the
          Packaging and Labelling of Dangerous Substances Regulations 1984, the Classification, Packaging and Labelling
          Regulations 1983, the Radioactive Material (Road Transport) (Great Britain) Regulations 1996 and the Carriage
          of Explosives by Road Regulations 1996. If we suspect you are in breach of clause 9.1, we will report you to
          the relevant authorities without notice.
        </p>
        <p>
          <b>7.3</b> You accept that, in the event that you breach clause 7.1, Sell Your Watch Today shall be entitled
          to deal with any such Items received as we see fit without making any payment to you (including reporting and
          delivering the relevant Items to the police without providing any notice to you) and shall be entitled to
          report such activity in full to the relevant authorities. Sell Your Watch Today has a zero tolerance policy on
          stolen goods and will report all Items that we suspect are stolen to the police.
        </p>
        <p>
          <b>7.4</b> You agree to pay Sell Your Watch Today for any loss or damage as a result of your breach of clause
          7.1, including but not limited to any cost that Sell Your Watch Today may incur from breaching any of our
          statutory obligations as a result of receiving an unlawful Item from you.
        </p>
        <p>
          <b>7.5</b> You agree that Sell Your Watch Today, and our Dealers, shall have no liability to you for any loss
          or damage that you may suffer, nor for any criminal prosecution to which you may become subject, arising from
          your breach of clause 7.1.
        </p>

        <h2>8. Use of Website</h2>
        <p>
          <b>8.1</b> We do not guarantee that our Website, or any content on it, will always be available or be
          uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our Website for
          business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.
        </p>
        <p>
          <b>8.2</b> Our Website is directed to people residing in the UK. We do not represent that content available on
          or through our Website is appropriate for use or available in other locations.
        </p>
        <p>
          <b>8.3</b> If you choose, or are provided with, a user identification code, password or any other piece of
          information as part of our security procedures, you must treat such information as confidential. You must not
          disclose it to any third party. We have the right to disable any user identification code or password, whether
          chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any
          of the provisions of these terms of use.
        </p>
        <p>
          <b>8.4</b> We do not guarantee that our Website will be secure or free from bugs or viruses. You are
          responsible for configuring your information technology and platform to access our Website. You should use
          your own virus protection software.
        </p>
        <p>
          <b>8.5</b> You must not misuse our Website by knowingly introducing viruses, trojans, worms, logic bombs or
          other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access
          to our site, the server on which our Website is stored or any server, computer or database connected to our
          Website. You must not attach our Website via a denial-of-service attach or a distributed denial-of-service
          attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990.
          We will report any such breach to the relevant law enforcement authorities and we will co-operate with those
          authorities by disclosing your identity to them. In the event of such a breach, your right to use our Website
          will cease immediately.
        </p>

        <h2>9. Sell Your Watch Today Complaints Procedure and ADR</h2>
        <p>
          <b>9.1</b> If you are not satisfied with the service provided please contact us in writing, detailing the
          nature of your complaint and your unique customer reference number, at info@sellyourwatchtoday.co.uk. We aim
          to respond to all correspondence within 5 business days. Any message sent to an alternate email address may
          not be handled within the specified timeframe.
        </p>
        <p>
          <b>9.2</b> If you are unhappy with our resolution you can submit an appeal to info@sellyourwatchtoday.co.uk.
        </p>
        <p>
          <b>9.3</b> You may wish to seek independent legal advice on your complaint from the Citizens Advice consumer
          helpline, 0808 223 1133.
        </p>
        <p>
          <b>9.4</b> If following our internal complaints procedure, you believe the issue has not been appropriately
          resolved you may wish to submit a complaint to an Alternative Dispute Resolution (ADR) provider. ADR is a
          dispute resolution procedure where an independent body considers the facts of a dispute and seeks an out of
          court resolution.
        </p>
      </div>
    </>
  );
}

export default TermsAndConditions;
