import { useEffect } from "react";
import { initializeApp } from "firebase/app";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import classes from "./App.module.css";
import config from "./firebase/Config";

import NavBar from "./components/NavBar/NavBar";
import BurgerNav from "./components/BurgerNav/BurgerNav";
import FooterNav from "./components/FooterNav/FooterNav";
import Homepage from "./routes/Homepage/Homepage";
import GetOffers from "./routes/GetOffers/GetOffers";
import HowItWorks from "./routes/HowItWorks/HowItWorks";
import OurDealers from "./routes/OurDealers/OurDealers";
import ContactUs from "./routes/ContactUs/ContactUs";
import TermsAndConditions from "./routes/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./routes/PrivacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./routes/CookiesPolicy/CookiesPolicy";
import Sitemap from "./routes/Sitemap/Sitemap";

function App() {
  useEffect(() => {
    initializeApp(config);
  }, []);

  return (
    <>
      <BrowserRouter>
        <NavBar />
        <BurgerNav className={classes.burgerNav} />
        <Routes>
          <Route path={"/"} element={<Homepage />} />
          <Route path={"/GetOffers"} element={<GetOffers />} />
          <Route path={"/HowItWorks"} element={<HowItWorks />} />
          <Route path={"/OurDealers"} element={<OurDealers />} />
          <Route path={"/ContactUs"} element={<ContactUs />} />
          <Route path={"/TermsAndConditions"} element={<TermsAndConditions />} />
          <Route path={"/PrivacyPolicy"} element={<PrivacyPolicy />} />
          <Route path={"/CookiesPolicy"} element={<CookiesPolicy />} />
          <Route path={"/Sitemap"} element={<Sitemap />} />
        </Routes>
        <FooterNav />
      </BrowserRouter>
    </>
  );
}

export default App;
