import watchImage from "./assets/Omega-Large-18ct-Gold.webp";
import classes from "./Homepage.module.css";
import { Link } from "react-router-dom";

export default function Homepage() {
  return (
    <>
      <img src={watchImage} alt="Omega Watch" className={classes.watchImage} />
      <div className={classes.container}>
        <div className={classes.textBlock}>
          <h1>One form. Multiple offers.</h1>
          <p className={classes.silverText}>
            Get offers for your watch from verified watch
            <br />
            dealers all around the country.
          </p>
          <p className={classes.goldText}>No fees. No commitment.</p>
          <Link to="/GetOffers">
            <button className={classes.getOffersButton}>Get offers</button>
          </Link>
        </div>
      </div>
    </>
  );
}
