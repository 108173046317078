import { useRef } from "react";
import uploadSquare from "../assets/upload_square.png";
import classes from "./UploadTile.module.css";

export default function UploadTile(props) {
  const [image, setImage] = props.imageState;
  const imageRef = useRef();

  function handleImageChanged(e) {
    e.preventDefault();
    const newFile = imageRef.current.files[0];
    setImage(newFile);
  }

  return (
    <label className={classes.wrapper}>
      <img
        src={image === "" ? uploadSquare : URL.createObjectURL(image)}
        className={image === "" ? classes.notUploaded : classes.uploaded}
        alt="upload"
      />
      <input type="file" accept=".png, .jpeg, .jpg" ref={imageRef} onChange={handleImageChanged} />
    </label>
  );
}
