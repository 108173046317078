import classes from "./HowItWorksTile.module.css";

function HowItWorksTile(props) {
  return (
    <div className={classes.stepsContainer}>
      <div className={classes.steps}>
        <div className={classes.stepNumber}>
          <h2>{props.number}</h2>
        </div>
        <div className={classes.stepDescription}>
          <h2>{props.title}</h2>
          {props.description}
        </div>
      </div>
    </div>
  );
}

export default HowItWorksTile;
