import classes from "./Submitted.module.css";
import { MdCelebration } from "react-icons/md";

export default function Submitted() {
  return (
    <div className={classes.container}>
      <div className={classes.textBlock}>
        <MdCelebration className={classes.celebrationIcon} />
        <h1>You're all done!</h1>
        <h3>
          Thanks for your submission! You should receive a confirmation email shortly (make sure you check your spam
          folder so you don't miss any offers!).
        </h3>
        <h2>What happens next?</h2>
        <h3>
          We will now check over your submission and get in touch if there’s any extra information that we think will
          get you a better offer. If it all looks good we will send it out to the dealers to make you some offers!
        </h3>
      </div>
    </div>
  );
}
