import classes from "./CookiesPolicy.module.css";
import BackToHomepage from "../../components/BackToHomepage/BackToHomepage";

function CookiesPolicy() {
  return (
    <>
      <BackToHomepage />
      <div className={classes.container}>
        <h1>Cookies Policy</h1>

        <h2>Our use of cookies</h2>
        <p>
          Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with
          a good experience when you use our website and also allows us to improve our site. By continuing to browse the
          site, you are agreeing to our use of cookies.
        </p>

        <h2>What is a cookie?</h2>
        <p>
          At its simplest, a cookie is a small file that a website stores on your computer so it can recognise you when
          you return to the website and remember something about you at a later date.
        </p>

        <h2>Strictly necessary cookies</h2>
        <p>
          These are cookies that are required for the operation of our website. They include, for example, cookies that
          enable you to sign into your Sell Your Watch Today account and see your offers again.
        </p>

        <h2>Analytical/performance cookies</h2>
        <p>
          These cookies allow us to recognise and count the number of visitors and to see how visitors move around our
          website when they are using it. This helps us to improve the way our website works, for example, by ensuring
          that users are finding what they are looking for easily.
        </p>

        <h2>Functionality cookies</h2>
        <p>
          These are used to recognise you when you return to our website. This enables us to personalise our content for
          you, greet you by name and remember your preferences (for example, remembering the number plate you searched
          on Sell Your Watch Today so you don’t have to type it every time).
        </p>

        <h2>Targeting cookies</h2>
        <p>
          Please note that third parties (including, for example, advertising networks and providers of external
          services like web traffic analysis services) may also use cookies, over which we have no control. These
          cookies are likely to be analytical/performance cookies or targeting cookies.
        </p>

        <h2>Third party cookies</h2>
        <p>
          These cookies allow us to recognise and count the number of visitors and to see how visitors move around our
          website when they are using it. This helps us to improve the way our website works, for example, by ensuring
          that users are finding what they are looking for easily.
        </p>

        <h2>Opting-out of cookies</h2>
        <p>
          While continued use of the Sell Your Watch Today website assumes compliance with our current cookie policy,
          you can change your privacy settings at any time. Opting out of cookies is easy, but how do you do it depends
          on the browser you’re using. The best guide to turning off cookies is available at{" "}
          <a href="https://cookiesandyou.com" target="_blank" rel="noreferrer">
            cookiesandyou.com.
          </a>
        </p>
      </div>
    </>
  );
}

export default CookiesPolicy;
