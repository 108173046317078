import { Link } from "react-router-dom";
import classes from "./PrivacyPolicy.module.css";
import BackToHomepage from "../../components/BackToHomepage/BackToHomepage";

function PrivacyPolicy() {
  return (
    <>
      <BackToHomepage />
      <div className={classes.container}>
        <h1>Privacy Policy</h1>
        <p>
          Sell Your Watch Today a trading name of Unlimited Innovation Ltd offers price comparison services for watch
          related transactions (“Services”) on this website, sellyourwatchtoday.co.uk (“Website”). In order to provide
          these Services, we require certain information about you and your watch. This policy sets out the types of
          personal information we collect and process about you, what we use it for and how we will treat it. Please
          read this policy carefully and contact us if you have any questions as set out below.
        </p>
        <h2>Information we process about you</h2>
        <p>When you use Services we will collect certain information about you, such as:</p>

        <ul>
          <li>Basic contact information you provide to us;</li>
          <li>Photographs related to your watch</li>
          <li>Records of how you use our Services</li>
          <li>Information we receive from third parties (e.g. advertising networks and analytics providers.);</li>
          <li>Records of communications you have with us (e.g. emails, phone calls)</li>
          <li>
            Technical information about your device and website usage, including cookies (see our Cookies Policy);
          </li>
        </ul>

        <h2>Contact information you provide to us</h2>
        <p>
          If you decide to complete a submission of your watch, we will collect your name, email address and telephone
          number and information about your watch, such as the brand, model and year (see also ‘Submission information’
          below for a full list). We will use this information so we can contact you with Offers.
        </p>

        <h2>Submission information you provide to us</h2>
        <p>As part of our Services we will use Submission information to receive Offers for your watch, such as:</p>
        <ul>
          <li>The watch brand.</li>
          <li>The watch model.</li>
          <li>The watch manufacture year.</li>
          <li>Whether or not the watch is working or not.</li>
          <li>Whether or not the watch has its original box.</li>
          <li>Whether or not the watch has its original paperwork.</li>
        </ul>

        <h2>Your bank account details</h2>
        <p>
          Once an offer has been accepted we will request the bank account details for the account into which you wish
          to receive payment. This includes your name, account number and sort code.
        </p>

        <h2>Records of how you use our Services</h2>
        <p>
          We will keep a record of how you use our website, the Submission information, the offers you receive and, in
          the event of a sale, the price you accept for your watch.
        </p>

        <h2>Record of communications you have with us</h2>
        <p>
          If you contact us with a question or query or to provide us with feedback, we will keep a record of your
          communications with us.
        </p>

        <h2>Other information you provide to us voluntarily </h2>
        <p>
          This may include data that you provide us of any feedback that you provide to one of our surveys or focus
          groups.
        </p>

        <h2>Technical information about your device and website usage</h2>
        <p>When you use our Website, we collect certain technical information including:</p>
        <ul>
          <li>Details about your device, such as your IP address, device type, operating system and browser;</li>
          <li>
            Information relating to use of the Website, such as the pages you visit, the time spent on each page and the
            elements of the page you interact with.{" "}
          </li>
        </ul>

        <h2>How we use your personal information </h2>
        <p>
          We use personal information about you and your Submission for various purposes connected with our Services,
          including:
        </p>
        <ul>
          <li>
            To facilitate your use of our Services, which includes sharing your information with our network of approved
            watch buying partners via our platform and email. Who provides offers for your watch and to connect you with
            them.{" "}
          </li>
          <li>
            To communicate with you in relation to your use of our Services and provide you with customer support.
          </li>
          <li>For internal management, administrative and organisational reasons.</li>
          <li>
            To share information with our suppliers and other third parties in order to provide you with our Services.
          </li>
          <li>To develop and improve our business, through the use of data analytics and benchmarking. </li>
        </ul>
        <p>
          In order to use your personal information, we must have a legal basis for doing so. We will only use your
          personal information where it is necessary:
        </p>
        <ul>
          <li>To fulfil our contract for services with you;</li>
          <li>To comply with a legal obligation to which we are subject;</li>
          <li>For our legitimate business interests that are not overridden by your interests, rights and freedoms.</li>
        </ul>
        <p>
          Where none of the above applies, we will request your consent (which we will ask for before we process the
          information).
        </p>

        <h2>Who do we share your information with, and for what purposes?</h2>
        <p>
          We may share your Submission information with our network of Watch Buying Partners so that we can receive
          Offers for your watch.
        </p>

        <h2>Our Watch Buying Partners </h2>
        <p>
          When you request Offers for your watch, we share information about your watch (detailed above in ‘Submission
          information you provide to us’ section). The Offer is valid for 7 days. If you accept an Offer for your watch
          provided by a Watch Buying Partner we will also share with them tracking details of the watch once dispatched.
        </p>
        <p>
          We may also share your information with our Watch Buying Partners for Analysis, reporting, billing and
          debugging purposes. If you proceed with the sale of your watch, we will receive information about your
          transaction from the relevant Watch Buying Partner, such as the date of transaction and the accepted Offer
          amount.
        </p>

        <h2>Storage of your personal information </h2>
        <p>
          Information about you may be transferred outside the UK to a country, territory or international organisation
          that may not have UK equivalent data protection standards.
        </p>
        <p>
          This may be when we transfer your personal information to our trusted third party service providers (for the
          purposes described above) outside of the European Union. In such cases, we will ensure that your personal
          information is protected by implementing appropriate safeguards, such as a European Commission adequacy
          decision, the EU-US Privacy Shield Certification or the EU Model Contracts, If you would like more information
          about any of the transfer safeguards on which we rely please contact us as set out in this privacy policy.
        </p>

        <h2>How do we protect your personal information?</h2>
        <p>
          We implement appropriate technical and organisational measures to protect personal information that we hold
          from unauthorised disclosure, use, alteration or destruction. Where appropriate, we use encryption and other
          technologies that can assist in securing the information you provide. We also require our service providers to
          comply with strict data privacy requirements.
        </p>

        <h2>How long will your personal information be kept?</h2>
        <p>
          The period for which we may retain information about you will depend on the purposes for which the information
          was collected, whether you have requested the deletion of the information, and whether any legal obligations
          require the retention of it (for example, for regulatory compliance). We will not retain information about you
          for longer than is necessary to fulfil the purposes for which it was collected.
        </p>

        <h2>Your rights</h2>
        <p>You may have some or all of the following rights in respect of the information about you that we process:</p>
        <ul>
          <li>request us to give you access to it;</li>
          <li>request us to rectify it, update it, or erase it;</li>
          <li>request us to restrict our using it, in certain circumstances;</li>
          <li>object to our using it, in certain circumstances;</li>
          <li>withdraw your consent to our using it;</li>
          <li>data portability, in certain circumstances;</li>
          <li>opt out from our using it for direct marketing; and</li>
          <li>
            lodge a complaint with the Information Commissioner’s Office in the UK which is responsible for upholding
            your data protection rights.
          </li>
        </ul>
        <p>
          You are able to exercise these rights by contacting us through the <Link to={`/ContactUs`}>'Contact Us'</Link>{" "}
          section
        </p>

        <h2>Contact information</h2>
        <p>
          For the purpose of UK data protection law, the controller is Unlimited Innovation Ltd (Company Number
          13883827) of 2 Chapel Road, Carleton Rode, Norwich, Norfolk, NR16 1RN.
        </p>
        <p>
          If you have any questions, or wish to exercise your rights, please contact us at any time on
          info@sellyourwatchtoday.co.uk
        </p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
