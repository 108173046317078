import classes from "./BurgerNav.module.css";
import logo from "../../assets/logo.webp";
import { Link } from "react-router-dom";
import { useState } from "react";
import { HiMenu } from "react-icons/hi";
import { AnimatePresence, motion } from "framer-motion";

export default function BurgerNav(props) {
  const [menuHidden, setMenuHidden] = useState(true);
  const menuTransition = {
    duration: 0.8,
    type: "spring",
  };
  const animationVariants = {
    visible: { x: 0, y: "var(--navbar-height)", display: "inline-block" },
    hidden: { y: "-100%" },
  };
  function closeMenu() {
    setMenuHidden(true);
  }
  function toggleMenu() {
    setMenuHidden(!menuHidden);
  }

  return (
    <div className={props.className}>
      <HiMenu className={classes.burger} onClick={toggleMenu} />
      <AnimatePresence>
        {!menuHidden && (
          <motion.div
            className={classes.container}
            variants={animationVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={menuTransition}
            onClick={closeMenu}
          >
            <ul className={classes.navItemList}>
              <li className={classes.navItem}>
                <Link className={classes.navLink} to={"/"} onClick={closeMenu}>
                  Home
                </Link>
              </li>
              <li className={classes.navItem}>
                <Link className={classes.navLink} to={"/GetOffers"} onClick={closeMenu}>
                  Get Offers
                </Link>
              </li>
              <li className={classes.navItem}>
                <Link className={classes.navLink} to={"/HowItWorks"} onClick={closeMenu}>
                  How It Works
                </Link>
              </li>
              <li className={classes.navItem}>
                <Link className={classes.navLink} to={"/OurDealers"} onClick={closeMenu}>
                  Our Dealers
                </Link>
              </li>
              <li className={classes.navItem}>
                <Link className={classes.navLink} to={"/ContactUs"} onClick={closeMenu}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
      <div className={classes.logoWrapper}>
        <Link to="/">
          <img src={logo} alt="Sell Your Watch Today" className={classes.logo} />
        </Link>
      </div>
    </div>
  );
}
