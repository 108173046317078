import { IoChevronBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import classes from "./BackToHomepage.module.css";

export default function BackToHomepage() {
  return (
    <Link to="/">
      <IoChevronBack className={classes.backIcon} />
    </Link>
  );
}
