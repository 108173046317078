import classes from "./Submitted.module.css";

export default function Submitted() {
  return (
    <div className={classes.container}>
      <div className={classes.textBlock}>
        <h1>Thanks for getting in touch!</h1>
        <h3>A member of our team will contact you shortly to help with your enquiry.</h3>
        <h3>
          We aim to respond to all correspondence within 24 hours but at busy times this is not always possible, so your
          patience in these circumstances is greatly appreciated. 
        </h3>
      </div>
    </div>
  );
}
