import { Link } from "react-router-dom";
import classes from "./FooterNav.module.css";

function FooterNav() {
  return (
    <div className={classes.footer}>
      <p className={classes.copyright}>© 2022 - Unlimited Innovation Ltd - All rights reserved</p>
      <div className={classes.linkContainer}>
        <Link to={`/TermsAndConditions`} className={classes.footerLink}>
          Terms
        </Link>
        <Link to={`/PrivacyPolicy`} className={classes.footerLink}>
          Privacy
        </Link>
        <Link to={`/CookiesPolicy`} className={classes.footerLink}>
          Cookies
        </Link>
        <Link to={`/Sitemap`} className={classes.footerLink}>
          Sitemap
        </Link>
      </div>
    </div>
  );
}

export default FooterNav;
