import classes from "./ItemDetails.module.css";
import { FormContext } from "../../context/FormContext";
import { useContext } from "react";
import TextInput from "../../../../components/TextInput/TextInput";
import YesNoInput from "../../../../components/YesNoInput/YesNoInput";

export default function ItemDetails() {
  const { brandState, modelState, yearState, workingState, boxState, papersState, otherInfoState } =
    useContext(FormContext);
  const [otherInfo, setOtherInfo] = otherInfoState;

  return (
    <div className={classes.container}>
      <h1>Tell us about your watch</h1>
      <h3>The more information you can provide the more likely you are to get good offers</h3>
      <div className={classes.inputsContainer}>
        <div className={classes.inputRow}>
          <TextInput state={brandState} title="Watch Brand" subtext="(Required)" />
          <div className={classes.inputDivider} />
          <TextInput state={modelState} title="Watch Model" subtext="(Optional)" />
          <div className={classes.inputDivider} />
          <TextInput state={yearState} title="Watch Year" subtext="(Optional)" />
        </div>
        <div className={classes.inputRow}>
          <YesNoInput state={workingState} question="Is the watch working?" />
          <div className={classes.inputDivider} />
          <YesNoInput state={boxState} question="Do you have the original box?" />
          <div className={classes.inputDivider} />
          <YesNoInput state={papersState} question="Do you have the original papers?" />
        </div>
      </div>

      <div className={classes.textContainer}>
        <label>
          Can you give us any other information about your watch?
          <textarea value={otherInfo} onChange={(e) => setOtherInfo(e.target.value)} />
        </label>
      </div>
    </div>
  );
}
