export const config = {
  apiKey: "AIzaSyBPblcN1_7KmgSzQVxqyl7WTlds_Xyb_b4",
  authDomain: "sell-your-watch-today.firebaseapp.com",
  projectId: "sell-your-watch-today",
  storageBucket: "sell-your-watch-today.appspot.com",
  messagingSenderId: "460803225122",
  appId: "1:460803225122:web:fe8966244b1ebd1b823c7b",
  measurementId: "G-43VMDWT68Q",
};

export default config;
