import classes from "./Sitemap.module.css";
import BackToHomepage from "../../components/BackToHomepage/BackToHomepage";

function Sitemap() {
  return (
    <>
      <BackToHomepage />
      <div className={classes.container}>
        <h1>Sitemap</h1>
        <div className={classes.linkList}>
          <a href={`https://sellyourwatchtoday.co.uk/`}>Home</a>
          <a href={`https://sellyourwatchtoday.co.uk/GetOffers`}>Get Offers</a>
          <a href={`https://sellyourwatchtoday.co.uk/HowItWorks`}>How It Works</a>
          <a href={`https://sellyourwatchtoday.co.uk/OurDealers`}>Our Dealers</a>
          <a href={`https://sellyourwatchtoday.co.uk/ContactUs`}>Contact Us</a>
          <a href={`https://sellyourwatchtoday.co.uk/TermsAndConditions`}>Terms</a>
          <a href={`https://sellyourwatchtoday.co.uk/PrivacyPolicy`}>Privacy Policy</a>
          <a href={`https://sellyourwatchtoday.co.uk/CookiesPolicy`}>Cookies Policy</a>
          <a href={`https://sellyourwatchtoday.co.uk/Sitemap`}>Sitemap</a>
        </div>
      </div>
    </>
  );
}

export default Sitemap;
