import classes from "./TextInput.module.css";

export default function TextInput(props) {
  const [value, setValue] = props.state;

  return (
    <label className={classes.wrapper}>
      {props.title}
      <input
        type={props.type ? props.type : "text"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={props.wide && classes.wideInput}
      />
      {props.subtext && <span className={classes.smallText}>{props.subtext}</span>}
    </label>
  );
}
