import { useState } from "react";
import classes from "./ContactUs.module.css";
import TextInput from "../../components/TextInput/TextInput.jsx";
import Submitted from "./components/Submitted/Submitted";
import { sendContactFormEmails } from "../../firebase/HelperFunctions";
import ReactLoading from "react-loading";
import { isValidEmail } from "../../Tools/HelperFunctions";
import BackToHomepage from "../../components/BackToHomepage/BackToHomepage";

export default function ContactUs() {
  const [submissionStatus, setSubmissionStatus] = useState("NOT SUBMITTED");
  const [message, setMessage] = useState("");
  const formState = {
    nameState: useState(""),
    emailState: useState(""),
    phoneState: useState("+44"),
    subjectState: useState(""),
    messageState: [message, setMessage],
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (!isValidEmail(formState.emailState[0])) {
      window.alert("Please enter a valid email address");
      return;
    }
    setSubmissionStatus("IN PROGRESS");
    sendContactFormEmails(formState)
      .then(() => {
        setSubmissionStatus("SUBMITTED");
      })
      .then(resetFormState);
  }

  function resetFormState() {
    for (const stateName in formState) {
      const [value, setValue] = formState[stateName];
      if (typeof value === "boolean") {
        setValue(false);
      } else {
        setValue("");
      }
    }
  }

  function requiredFieldsFilled() {
    return (
      formState.nameState[0] !== "" &&
      formState.emailState[0] !== "" &&
      formState.subjectState[0] !== "" &&
      message !== ""
    );
  }

  if (submissionStatus === "IN PROGRESS") {
    return (
      <div className="centered">
        <ReactLoading type="bars" color="#d3ba8d" width="100px" />
      </div>
    );
  }
  if (submissionStatus === "SUBMITTED") {
    return (
      <>
        <BackToHomepage />
        <Submitted />
      </>
    );
  }
  return (
    <>
      <BackToHomepage />
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <h1>Want to get in touch?</h1>
          <h3>Fill in this form and one of our team will get back to you as soon as possible</h3>
          <div className={classes.contactContainer}>
            <div className={classes.firstRow}>
              <TextInput state={formState.nameState} title="Your name" subtext="(Required)" />
              <div className={classes.inputDivider} />
              <TextInput state={formState.emailState} title="Your email address" subtext="(Required)" />
              <div className={classes.inputDivider} />
              <TextInput state={formState.phoneState} title="Your phone number" subtext="(Optional)" />
            </div>

            <div className={classes.secondRow}>
              <TextInput state={formState.subjectState} title="Message subject" subtext="(Required)" />
            </div>

            <div className={classes.textContainer}>
              <label>Message</label>
              <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
            </div>

            <div className={classes.buttonContainer}>
              <button onClick={handleSubmit} disabled={submissionStatus === "IN PROGRESS" || !requiredFieldsFilled()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
